import React, { useState, useEffect } from 'react'
import {
    Box,
    Flex,
    Icon,
    SimpleGrid,
    FormControl,
    FormLabel,
    Input,
    useColorModeValue,
    Spinner,
    Select,
} from "@chakra-ui/react"

import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import MiniStatistics from "components/card/MiniStatistics"
import IconBox from "components/icons/IconBox"
import CheckTable from "views/admin/default/components/CheckTable"
import {
    MdAttachMoney,
    MdBarChart,
    MdFileCopy, MdSwapHoriz, MdTrendingUp
} from "react-icons/md"
import axiosInstance from "../../../api/axiosInstance"
import { formatNumber } from "../../../Utils/Utils"
import dayjs from "dayjs"
import { formatInTimeZone } from 'date-fns-tz'
import DailyTraffic from "./DailyTraffic";
import TotalSpent from "./TotalSpent";
import TrafficByProduct from "./TrafficByProduct";
import PieCard from "./PieCard";
import BestSellingOptionsChart from "./BestSellingOption";

const Dashboard = () => {
    const [loading, setLoading] = useState(true)
    const [metrics, setMetrics] = useState({})
    const [startDate, setStartDate] = useState(dayjs())
    const [endDate, setEndDate] = useState(dayjs())
    const [period, setPeriod] = useState('yesterday')
    const [selectedRange, setSelectedRange] = useState('Today')
    const [roasLoading, setRoasLoading] = useState(false)
    const timeZone = 'Europe/Bucharest'

    useEffect(() => {
        updateDateRange(selectedRange)
    }, [])

    useEffect(() => {
        fetchMetrics(startDate, endDate)
    }, [startDate, endDate])

    const fetchMetrics = async (start, end) => {
        setLoading(true)
        try {
            const formattedStartDate = start ? formatInTimeZone(start.toDate(), timeZone, 'yyyy-MM-dd') : undefined
            const formattedEndDate = end ? formatInTimeZone(end.toDate(), timeZone, 'yyyy-MM-dd') : undefined
            const response = await axiosInstance.get('/reports/metrics', {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                params: {
                    start_date: formattedStartDate,
                    end_date: formattedEndDate,
                },
            })
            setMetrics(response.data)
        } catch (error) {
            console.error('Error fetching metrics:', error)
        } finally {
            setLoading(false)
        }
    }

    const handleStartDateChange = (newDate) => {
        setStartDate(dayjs(newDate))
        if (selectedRange !== 'Custom') {
            setSelectedRange('Custom')
        }
    }

    const handleEndDateChange = (newDate) => {
        setEndDate(dayjs(newDate))
        if (selectedRange !== 'Custom') {
            setSelectedRange('Custom')
        }
    }

    const updateDateRange = (range) => {
        setSelectedRange(range)
        switch (range) {
            case 'Today':
                setStartDate(dayjs().startOf('day'))
                setEndDate(dayjs().endOf('day'))
                setPeriod('yesterday')
                break
            case 'Yesterday':
                setStartDate(dayjs().subtract(1, 'day').startOf('day'))
                setEndDate(dayjs().subtract(1, 'day').endOf('day'))
                setPeriod('the day before yesterday')
                break
            case 'This week':
                setStartDate(dayjs().startOf('week').add(1, 'day'))
                setEndDate(dayjs().endOf('week').add(1, 'day'))
                setPeriod('last week')
                break
            case 'Last week':
                setStartDate(dayjs().subtract(1, 'week').startOf('week'))
                setEndDate(dayjs().subtract(1, 'week').endOf('week'))
                setPeriod('the week before last week')
                break
            case 'This month':
                setStartDate(dayjs().startOf('month'))
                setEndDate(dayjs().endOf('month'))
                setPeriod('last month')
                break
            case 'Last month':
                setStartDate(dayjs().subtract(1, 'month').startOf('month'))
                setEndDate(dayjs().subtract(1, 'month').endOf('month'))
                setPeriod('the month before last month')
                break
            case 'This year':
                setStartDate(dayjs().startOf('year'))
                setEndDate(dayjs().endOf('year'))
                setPeriod('last year')
                break
            case 'Maximum':
                setStartDate(null)
                setEndDate(null)
                setPeriod(null)
                break
            default:
                setStartDate(dayjs().startOf('day'))
                setEndDate(dayjs().endOf('day'))
        }
    }

    const calculatePercentageChange = (current, previous) => {
        if (!previous) return 0
        return ((current - previous) / previous) * 100
    }

    const brandColor = useColorModeValue("brand.500", "white")
    const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100")

    const formattedProductSales = metrics.productSales?.map(product => ({
        name: [product.name, false],
        totalSales: `${formatNumber(product.totalSales)} RON`,
        quantity: formatNumber(product.orderCount),
        unitsSold: product.unitsSold
    }))

    const fetchRoasDetails = async () => {
        setRoasLoading(true)
        try {
            const response = await axiosInstance.get('/roas/details', {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            })

            fetchMetrics(startDate, endDate)
        } catch (error) {
            console.error('Error fetching ROAS details:', error)
        } finally {
            setRoasLoading(false)
        }
    }

    if (loading) {
        return (
            <Flex justify="center" align="center" height="100vh">
                <Spinner size="xl" />
            </Flex>
        )
    }

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }} maxWidth="xxl" px={{ base: '20px', md: '40px' }}>

            <SimpleGrid
                columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
                gap="20px"
                mb="20px"
            >
                <FormControl>
                    <FormLabel>Start Date</FormLabel>
                    <DatePicker
                        selected={startDate?.toDate()}
                        onChange={handleStartDateChange}
                        customInput={<Input />}
                        dateFormat="dd-MM-yyyy"
                    />
                </FormControl>

                <FormControl>
                    <FormLabel>End Date</FormLabel>
                    <DatePicker
                        selected={endDate?.toDate()}
                        onChange={handleEndDateChange}
                        customInput={<Input />}
                        dateFormat="dd-MM-yyyy"
                    />
                </FormControl>

                <FormControl>
                    <FormLabel>Date Range</FormLabel>
                    <Select value={selectedRange} onChange={(e) => updateDateRange(e.target.value)}>
                        <option value="Today">Today</option>
                        <option value="Yesterday">Yesterday</option>
                        <option value="This week">This Week</option>
                        <option value="Last week">Last Week</option>
                        <option value="This month">This Month</option>
                        <option value="Last month">Last Month</option>
                        <option value="This year">This Year</option>
                        <option value="Maximum">Maximum</option>
                    </Select>
                </FormControl>
            </SimpleGrid>

            <SimpleGrid
                columns={{ base: 1, md: 2, lg: 3, xl: 3, "2xl": 3, "3xl": 6 }}
                gap="20px"
                mb="20px"
            >
                <MiniStatistics
                    startContent={
                        <IconBox
                            w="56px"
                            h="56px"
                            bg={boxBg}
                            icon={<Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />}
                        />
                    }
                    period={period}
                    name="Total Sales"
                    value={`${formatNumber(metrics.totalSales)} RON`}
                    growth={calculatePercentageChange(
                        metrics.totalSales,
                        metrics.previousTotalSales
                    )}
                />

                <MiniStatistics
                    startContent={
                        <IconBox
                            w="56px"
                            h="56px"
                            bg={boxBg}
                            icon={<Icon w="32px" h="32px" as={MdFileCopy} color={brandColor} />}
                        />
                    }
                    period={period}
                    name="Orders"
                    value={formatNumber(metrics.orderCount)}
                    growth={calculatePercentageChange(
                        metrics.orderCount,
                        metrics.previousOrderCount
                    )}
                />

                <MiniStatistics
                    startContent={
                        <IconBox
                            w="56px"
                            h="56px"
                            bg={boxBg}
                            icon={<Icon w="32px" h="32px" as={MdAttachMoney} color={brandColor} />}
                        />
                    }
                    period={period}
                    name="Average Order Value"
                    value={`${formatNumber(metrics.averageOrderValue)} RON`}
                    growth={calculatePercentageChange(
                        metrics.averageOrderValue,
                        metrics.previousAverageOrderValue
                    )}
                />

                <MiniStatistics
                    startContent={
                        <IconBox
                            w="56px"
                            h="56px"
                            bg={boxBg}
                            icon={<Icon w="32px" h="32px" as={MdAttachMoney} color={brandColor} />}
                        />
                    }
                    period={period}
                    name="Spend"
                    value={`${formatNumber(metrics.totalSpend)} RON`}
                    growth={calculatePercentageChange(
                        metrics.totalSpend,
                        metrics.previousTotalSpend
                    )}
                />

                <div onDoubleClick={fetchRoasDetails}>
                    <MiniStatistics
                        startContent={
                            <IconBox
                                w="56px"
                                h="56px"
                                bg={roasLoading ? "gray.200" : boxBg}
                                icon={
                                    roasLoading ? (
                                        <Spinner size="md" />
                                    ) : (
                                        <Icon w="32px" h="32px" as={MdTrendingUp} color={brandColor} />
                                    )
                                }
                            />
                        }
                        period={period}
                        name="ROAS"
                        value={`${formatNumber(metrics.roas)}`}
                        growth={calculatePercentageChange(
                            metrics.roas,
                            metrics.previousRoas
                        )}
                    />
                </div>

                <MiniStatistics
                    startContent={
                        <IconBox
                            w="56px"
                            h="56px"
                            bg={boxBg}
                            icon={<Icon w="32px" h="32px" as={MdSwapHoriz} color={brandColor} />}
                        />
                    }
                    period={period}
                    name="Conversion Rate"
                    value={`${formatNumber(metrics.conversionRate)}%`}
                    growth={calculatePercentageChange(
                        metrics.conversionRate,
                        metrics.previousConversionRate
                    )}
                />
            </SimpleGrid>

            <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap="20px" mb="20px">
                <CheckTable
                    columnsData={[
                        { Header: "Product Name", accessor: "name" },
                        { Header: "Total Sales", accessor: "totalSales" },
                        { Header: "Total Orders", accessor: "orderCount" },
                        { Header: "Total Units Sold", accessor: "unitsSold" },
                    ]}
                    tableData={formattedProductSales || []}
                />
                <DailyTraffic />
            </SimpleGrid>

            <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap="20px" mb="20px">
                <TotalSpent/>
                <TrafficByProduct productTraffic={metrics.productTraffic || []} />
            </SimpleGrid>

            <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap="20px" mb="20px">
                <PieCard data={metrics.orderSummary || []}/>
                <BestSellingOptionsChart productSales={metrics.productSales || []}/>
            </SimpleGrid>
        </Box>
    )
}

export default Dashboard
